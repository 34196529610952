@font-face {
    font-family: Raleway-Regular;
    src: url(../fonts/Raleway/Raleway-Regular.ttf);
}
@font-face {
    font-family: Raleway-Bold;
    src: url(../fonts/Raleway/Raleway-Bold.ttf);
}
@font-face {
    font-family: Raleway-ExtraBold;
    src: url(../fonts/Raleway/Raleway-ExtraBold.ttf);
}

.App {
    background: #0f0f0f;
    color: #ffffff;
    font-family: Raleway-Regular;
}
/* HEADER */
.header_logo_text p{
    text-transform: uppercase;
    font-size: 36px;
    font-weight: bold;
    color: #ffffff;
    margin: 0 20px 0 20px;
}
.header_logo_text a{
    text-decoration: none;
}
.header_web_menu a{
    text-decoration: none;
    color: #7f7f7f;
}
.active_menu a{
    text-decoration: none;
    color: #ffffff;
}
/* FOOTER */
.footer {
    background: #177ffc;
    color: #ffffff;
}
.footer_logo p{
    text-transform: uppercase;
    font-size: 36px;
    font-weight: bold;
    color: #ffffff;
    margin: 0 20px 0 20px;
}
.footer_column_header p{
    font-size: 30px;
    font-weight: bold;
    color: #ffffff;
}
.footer_secondary_text a{
    text-decoration: none;
    color: #cdcfd0;
}
.footer_secondary_text p{
    text-decoration: none;
    color: #cdcfd0;
}
.footer_description {
    font-size: 13px;
}
.footer_description p{
    padding-left: 20px;
}
/* FONTS */
.fontSize32 {
    font-size: 24px;
}
/* HOMEPAGE */
.homepageFirstBlock h2{
    font-size: 46px;
    font-family: Raleway-ExtraBold;
}
.homepageFirstBlock small{
    font-size: 14px;
    color: #cdcfd0;
}
.homepageSecondBlock h2{
    text-align: center;
    font-size: 32px;
    font-family: Raleway-ExtraBold;
}
.homepageSecondBlock small{
    font-size: 14px;
    color: #cdcfd0;
}
.boxShadow {
    background: radial-gradient(circle, rgba(23,127,252,0.31200983811493344) 0%, rgba(15,15,15,1) 80%);
    border-radius: 45%;
}
.div_padding_20 {
    padding-top: 50px;
    padding-bottom: 50px;
}
/* EMAIL FORM */
.email_form_block h2{
    text-align: center;
    font-size: 32px;
    font-family: Raleway-ExtraBold;
    margin-bottom: 0;
}
.email_form_block small{
    font-size: 14px;
    color: #cdcfd0;
}
.email_form {
    width: 80%;
    align-content: center;
    margin: auto;
    background: rgb(23,127,252);
    background: linear-gradient(305deg, rgba(23,127,252,1) 43%, rgba(120,187,253,1) 100%);
    border-radius: 10px;
}
.email_form_button{
    color: #177ffc !important;
    background: #ffffff !important;
    height: 50px;
}
.email_form_button:hover{
    color: #0f0f0f !important;
    background: #ffffff;
}
.email_unsubscribe_form {
    width: 80%;
    align-content: center;
    margin: auto;
    background: rgb(252, 23, 23);
    background: linear-gradient(305deg, rgba(252, 23, 23,1) 43%, rgba(253, 120, 120,1) 100%);
    border-radius: 10px;
}
.email_unsubscribe_form small{
    font-size: 14px;
    color: #ffffff;
}
.blogArticleHeader{
    text-transform: uppercase;
}
.blogArticleDate{
    text-transform: uppercase;
}
.blogArticleText p{
    font-size: 20px;
}
.blogArticleText a{
    text-decoration: none;
    padding-left: 7px;
    padding-right: 7px;
}
blockquote {
    margin: 0;
    /*background: #177ffc;*/
    /*color: #131314;*/
    padding: 30px 30px 30px 90px;
    position: relative;
    font-family: 'Lato', sans-serif;
}
blockquote:before {
    content: "\201C";
    font-family: serif;
    position: absolute;
    top: 20px;
    left: 16px;
    color: black;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: white;
    font-size: 40px;
    font-weight: bold;
    text-align: center;
    line-height: 50px;
}
blockquote:after {
    content: "";
    width: 4px;
    background: white;
    position:absolute;
    left: 70px;
    top: 20px;
    bottom: 20px;
}
blockquote p {
    margin-top: 0;
    font-size: 24px !important;
    font-weight: 300;
}
blockquote cite {
    font-style: normal;
    text-transform: uppercase;
}
.coinSymbol {
    text-transform: uppercase;
}
.airdropsShortDescription {
    color: white;
}
.buttonLastAirdrops {
    width: 100%;
}
.buttonLastAirdrops a {
    text-transform: none;
    color: #ffffff;
    text-decoration: none;
    font-size: 20px;
    display: flex;
    align-items: center;
}
.buttonLastAirdrops a img{
    padding: 5px;
}
.buttonCryptocurrenciesSymbol a{
    text-decoration: none;
}

@media (max-width: 550px) {
    .footer_column_header {
        display: none;
    }
    .footer_description p{
        padding-left: 20px;
        padding-right: 10px;
    }
    .footer_menu_links p{
        padding-left: 20px;
    }
    .footer_social_media_links p{
        padding-left: 20px;
    }
    .buttonCryptocurrenciesSymbol{
        width: 250px;
    }
    .buttonCryptocurrenciesSymbol h2{
        font-size: 15px;
    }
}